import React from "react";
import { Link, graphql } from "gatsby";
import Layout from "../components/Layout";
import PostPreview from "../components/PostPreview";

const YearPageTemplate = ({data, pageContext}) => {
  const posts = data?.allMarkdownRemark?.edges;

  if(!posts) return null;

  return (
    <Layout>
      <h1>
      Posts tagged in {pageContext.year}
      </h1>
      {posts.map((post) => 
      <PostPreview {...post} />
      )}
    </Layout>
  )
}

export default YearPageTemplate;

// export const yearPageQuery = graphql`
//   query YearPage($year: Int) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(
//       limit: 1000
//       sort: { fields: [frontmatter___year], order: DESC }
//       filter: { frontmatter: { year: { in: [$year] } } }
//     ) {
//       totalCount
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             templateKey
//             description
//             featuredimage {
//               childImageSharp {
//                 fluid(maxWidth: 2048, quality: 100) {
//                   ...GatsbyImageSharpFluid
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// `;
